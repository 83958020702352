/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
    box-sizing: border-box;
}
/*
    2. Remove default margin
  */
* {
    margin: 0;
}
html,
body {
    width: 100%;
    min-height: 100%;
    padding-right: 0 !important;
}
/*
    Typographic tweaks!
    3. Add accessible line-height
    4. Improve text rendering
  */
body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}
/*
    5. Improve media defaults
  */
img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}
/*
    6. Remove built-in form typography styles
  */
input,
button,
textarea,
select {
    font: inherit;
}
/*
    7. Avoid text overflows
  */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}
/*
    8. Create a root stacking context
  */
#root,
#__next {
    isolation: isolate;
}

#root {
    min-height: 100%;
}

input,
textarea {
    caret-color: #0044ff;
}

html {
    background-color: #eff3f7;
}

@media screen and (min-width: 900px) {
    html {
        width: calc(100vw - 6px);
    }
}

*::-webkit-scrollbar {
    width: 6px;
    background-color: #eff3f7;
    height: 12px;
    border-radius: 50px;
}
*::-webkit-scrollbar-thumb {
    height: 6px;
    border-radius: 4px;
    background-color: #000000db;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #ffffff;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}
